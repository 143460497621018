import logo from "./logo.svg";
import "./App.css";

import Quiz from "./QuizPlay/Quiz";
const App = () => {
  // const { productData, initialise } = useContext(ApiContext);

  return (
    <div className="App">
      <Quiz />
    </div>
  );
};

export default App;
