import React, { useState } from "react";
import "../QuizPlay/Quiz.css";

export default function Quiz() {
  const Questions = [
    {
      QuestionText: "Who is Our Prime Minister",
      AnswerOption: [
        { AnswerText: "Rahul Gandhi", isCorrect: false },
        { AnswerText: "Narendra Modi", isCorrect: true },
        { AnswerText: "Yogi Aditya Nath", isCorrect: false },
        { AnswerText: "Nitish Kumar", isCorrect: false },
      ],
    },
    {
      QuestionText:
        "Which state is to establish India’s first Green Energy Convergence Project?",
      AnswerOption: [
        { AnswerText: "Kerla", isCorrect: false },
        { AnswerText: "Telangana", isCorrect: false },
        { AnswerText: "Odisa", isCorrect: false },
        { AnswerText: "Goa", isCorrect: true },
      ],
    },
    {
      QuestionText: "Who is Our Health Minister",
      AnswerOption: [
        { AnswerText: "Mansukh L.", isCorrect: true },
        { AnswerText: "Dr. Bharati Pravin", isCorrect: false },
        { AnswerText: "Shri Rajesh Bhushan", isCorrect: false },
        { AnswerText: "Hon Kwaku Agyeman", isCorrect: false },
      ],
    },
    {
      QuestionText:
        "The recently adopted Lucknow Declaration is related to which field",
      AnswerOption: [
        { AnswerText: "Environment Protection", isCorrect: false },
        { AnswerText: "Imports and Export", isCorrect: false },
        { AnswerText: "Defence Cooperation", isCorrect: true },
        { AnswerText: "E-Governance", isCorrect: false },
      ],
    },
    {
      QuestionText:
        "Which organisation is manufacturing around 30000 ventilators, ordered from the PM Cares Fund?",
      AnswerOption: [
        {
          AnswerText: " Defence Research and Development Organisation",
          isCorrect: false,
        },
        { AnswerText: " Bharat Electronics Limited", isCorrect: true },
        { AnswerText: "Hindustan Aeronautical Limited", isCorrect: false },
        { AnswerText: "Ordnance Factory Board", isCorrect: false },
      ],
    },
  ];
  const [yourScore, setYourScore] = useState(0);
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
  const [score, setScore] = useState(false);
  const handleAnswer = (a) => {
    if (a.isCorrect) {
      setYourScore(yourScore + 1);
    }
    if (currQuestionIndex < Questions.length - 1) {
      setTimeout(() => {
        const selectedAnswer = currQuestionIndex + 1;
        setCurrQuestionIndex(selectedAnswer);
      }, 300);
    } else {
      setTimeout(() => {
        setScore(true);
      }, 300);
    }
  };

  return (
    <div>
      Quiz
      <div className="Container">
        {score ? (
          <div
            className="card-quiz "
            style={{
              display: "flex",
              justifyContent: yourScore < 4 ? "center" : null,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {yourScore > 3 ? (
              <img
                src="https://media.tenor.com/F0UWHBTt6xQAAAAj/congratulations-congrats.gif"
                alt=""
              />
            ) : null}
            <div className="score-section">
              <h3 className="score-text">
                you have scored : {yourScore}
                <small>/5</small>
              </h3>
            </div>
          </div>
        ) : (
          <div className="card-quiz">
            <div>
              <h3>
                Question {currQuestionIndex + 1} <small>/ 5</small>
              </h3>
            </div>
            <div className="Question-title">
              {" "}
              {Questions[currQuestionIndex].QuestionText} ?
            </div>
            <div className="answer-section">
              {Questions[currQuestionIndex].AnswerOption.map((option) => {
                return (
                  <button
                    className="ans-btn"
                    onClick={(a) => handleAnswer(option)}
                  >
                    {option.AnswerText}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
